import * as React from 'react';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { LocalizedPageContext } from '../utils/types';
import Button from '../components/ui/button';
import { ImageGallery } from '../components/ui/imageGallery';

const NotFoundPage: FC<{
  pageContext: LocalizedPageContext;
}> = ({ pageContext }) => {
  const { t } = useTranslation(['notfound', 'general']);
  const { lang, alternateUrls } = pageContext;

  return (
    <Layout
      pageContext={pageContext}
      secondNav
      mainClasses="text-centered is-centered"
    >
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <section className="not-found is-centered text-centered">
        <h1 className="title-large title text-white">
          {t('hero.title')}
          <br />
        </h1>
        <ImageGallery
          alt="page_not_found"
          name={t('hero.heroImage')}
          maxWidth="450"
        />

        <Button to="/" size="large">
          {t('back_home', { ns: 'general' })}
        </Button>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
